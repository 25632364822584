import { useGlobalStore } from "#imports";

type tawk = {
	tawk_chat: boolean;
};

/**
 * show chat widget
 */
export const useShowChatWidget = () => {
	const globalStore = useGlobalStore();
	const tawkChat = globalStore.globalData as tawk;
	if (tawkChat.tawk_chat) {
		if (window.Tawk_API.isChatMinimized()) {
			window.Tawk_API.maximize();
		}
	} else if (tawkChat.landbot_chat) {
		globalStore.landbotChatBox.open();
	}
};
