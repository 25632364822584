<template>
	<div>
		<div
			class="border border-secondary px-4 py-6 rounded items-center text-center flex flex-col"
			v-if="props.mobile">
			<div class="mb-2">
				<NuxtImg loading="lazy" src="/icons/b-meeting.svg" alt="" class="w-14"/>
			</div>
			<div class="m-2 flex flex-col space-y-4">
				<h3 class="">{{ $t("chat.title") }}</h3>
				<div class="leading-7">{{ $t("chat.desc") }}</div>
			</div>
			<div class="space-y-6">
				<NuxtLink
					:to="
						'tel:' +
						globalStore.sitePrimaryPhoneNumber.country_code +
						globalStore.sitePrimaryPhoneNumber.call_to_action_number
					">
					<h3>
						{{ $t("chat.call") }}
						{{ globalStore.sitePrimaryPhoneNumber.display_number }}
					</h3>
				</NuxtLink>
				<BaseButton
					@click="useShowChatWidget"
					class="font-bold mt-4 md:mt-0"
					:text="$t('chat.button')"></BaseButton>
			</div>
		</div>

		<div
			v-else
			:class="{ 
				'border border-secondary px-4 md:px-8 lg:px-16 py-6 md:mx-8 my-14 md:my-20 grid md:grid-flow-col md:grid-rows-1 rounded  md:items-center md:text-left text-center': position != 'menu',
				'flex flex-col text-center': position == 'menu',
			}"
		>
			<div 
				class="place-self-center"
				:class="{ 
					'space-y-4 md:pr-6 md:place-self-start': position != 'menu',
					'order-1 my-8': position == 'menu',
				}"
			>
				<NuxtImg
					loading="lazy"
					:class="{ 
						'h-10 w-10': position == 'menu',
					}"
					src="/icons/b-meeting.svg"
					alt="meeting icon"
					width="62"
					height="60" />
			</div>
			<div 
				class="space-y-2 md:my-0 my-4"
				:class="{ 
					'order-0': position == 'menu',
				}"
			>
				<h3
					:class="{ 
						'text-sm leading-6': position == 'menu',
					}"
				>{{ $t("chat.title") }}</h3>
				<div 
					class="text-blog-14-sm"
					:class="{ 
						'text-xs': position == 'menu',
					}"
				>{{ $t("chat.desc") }}</div>
			</div>
			<div 
				class="place-self-center lg:place-self-end w-64 md:w-auto"
				:class="{ 
					'md:space-y-2 md:pl-6 ': position != 'menu',
					'space-y-6 order-2': position == 'menu',
				}"
			>
				<NuxtLink
					:to="
						'tel:' +
						globalStore.sitePrimaryPhoneNumber.country_code +
						globalStore.sitePrimaryPhoneNumber.call_to_action_number
					">
					<h3>
						{{ $t("chat.call") }}
						{{ globalStore.sitePrimaryPhoneNumber.display_number }}
					</h3>
				</NuxtLink>
				<BaseButton
					@click="useShowChatWidget"
					:class="[props.btnClass, menuClass]"
          			class="w-full"
					:text="$t('chat.button')"></BaseButton>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { useGlobalStore } from "~/stores/global";
	import { useShowChatWidget } from "@/utils/useTawkMessenger";

	export interface LiveChatBlockProps {
		mobile?: boolean;
		btnClass?: string;
		position?: string;
		imageClass?: string;
	}

	const props = withDefaults(defineProps<LiveChatBlockProps>(), {
		btnClass: (props) =>
			props.btnClass ?? "w-40 font-bold md:text-xs mt-4 md:mt-0",
		mobile: false,
		position: '',
		imageClass:''
	});

	const menuClass = ref(props.position === 'menu' ? ' h-10' : '');

	const globalStore = useGlobalStore();
</script>
